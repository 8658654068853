@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "./sidebar.css";
@import "./error.css";
@import "./order.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.page .top {
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  align-items: center;
}

.page .top i {
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

.login_form h1 {
  font-size: 30px;
  font-weight: 700;
  color: #e53935;
  white-space: nowrap;
}

.login_form .field {
  display: flex;
  align-items: center;
}

.login_form .field i {
  font-size: 25px;
  margin-right: 10px;
}

.login_form .button {
  display: flex;
  justify-content: end;
}

.box {
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  /* padding: 1rem; */
}

/* .box ul{
  display: flex;
  list-style: none;
}

.box ul li {
  padding: 0.7rem 1rem;
  transition: all 0.5s ease;
}

.box ul li:hover {
  background-color: #e40423;
  color: #f3f3f3;
}

.box ul li.active {
  background-color: #e40423;
  color: #f3f3f3;
}

.box ul li a {
  transition: all 0.1s ease;
}

.box ul li a:hover {
  color: #f3f3f3;
} */

.order::-webkit-scrollbar {
  display: none;
}

/* table thead tr,
th {
  text-transform: uppercase;
  font-size: 11pt;
  font-weight: 500;
}

table tbody tr td {
  vertical-align: middle;
  padding: 0 0.5rem !important;
} */

.Polaris-Frame-Loading__Level {
  width: 100%;
  height: 100%;
  transform-origin: 0;
  background-color: #e40423 !important;
  transition: transform var(--p-duration-500) linear;
}

.Polaris-Tabs__Tab--selected .Polaris-Tabs__Title::before {
  background: #e40423 !important;
}
