.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 260px;
  background: #002984;
  z-index: 100;
  transition: all 0.5s ease;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.sidebar.close {
  width: 78px;
}

.sidebar .logo_details {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}

.sidebar .logo_details i {
  font-size: 20px;
  color: #fff;
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  font-style: normal;
}

.sidebar .logo_details i img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 7px;
  margin: 0 14px 0 12px;
}

.sidebar .logo_details .logo_name {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  transition: 0.3s ease;
  transition-delay: 0.1s;
}

.sidebar.close .logo_details .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav_links {
  height: 100%;
  padding: 30px 0 150px 0;
  overflow: auto;
}

.sidebar .nav_links {
  overflow: visible;
}

.sidebar .nav_links::-webkit-scrollbar {
  display: none;
}

.sidebar .nav_links li {
  list-style: none;
  position: relative;
  transition: all 0.4s ease;
}

.sidebar .nav_links li:hover {
  background: #3f51b5;
}

.sidebar .nav_links li .iocn_link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar .nav_links li i {
  height: 50px;
  min-width: 70px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  transition: all 0.3s ease;
}

.sidebar .nav_links li i.arrow {
  cursor: pointer;
}

.sidebar .nav_links li.showMenu i.arrow {
  transform: rotate(-180deg);
}

.sidebar.close .nav_links i.arrow {
  display: none;
}

.sidebar .nav_links li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.sidebar .nav_links li a .link_name {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.sidebar.close .nav_links li a .link_name {
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav_links li .sub_menu {
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #3f51b5;
  display: none;
}

.sidebar .nav_links li.showMenu .sub_menu {
  display: block;
}

.sidebar .nav_links li .sub_menu a {
  font-size: 15px;
  color: #fff;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}

.sidebar .nav_links li .sub_menu a:hover {
  opacity: 1;
}

.sidebar.close .nav_links li .sub_menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  transition: all 0.4s ease;
  opacity: 0;
  display: block;
  pointer-events: none;
}

.sidebar.close .nav_links li:hover .sub_menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
}

.sidebar .nav_links li .sub_menu .link_name {
  display: none;
}

.sidebar.close .nav_links li .sub_menu .link_name {
  display: none;
}

.sidebar.close .nav_links li .sub_menu .link_name {
  font-size: 18px;
  opacity: 1;
  display: block;
}

.sidebar .nav_links li .sub_menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav_links li:hover .sub_menu.blank {
  top: 50%;
  transform: translateY(-50%);
}

.sidebar .profile_details {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #3f51b5;
  padding: 6px 0;
  max-width: 260px;
  transition: all 0.4s ease;
}

.sidebar.close .profile_details {
  width: 78px;
}

.sidebar.close .profile_details .name_job ._job {
  width: auto;
}

.sidebar .profile_details .name_job ._job  {
  width: 115px;
}

.sidebar .profile_details .profile_content {
  display: flex;
  align-items: center;

}

.sidebar .profile_details img {
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 12px;
  background: #3f51b5;
  transition: all 0.5s ease;
}

.sidebar.close .profile_details img {
  padding: 10px;
}

.sidebar .profile_details .profile_name,
.sidebar .profile_details .job {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
}

.sidebar.close .profile_details i,
.sidebar.close .profile_details .profile_name,
.sidebar.close .profile_details .job {
  display: none;
}

.sidebar .profile_details .job {
  font-size: 12px;
}

.page {
  position: relative;
  background: #e4e9f7;
  height: 100vh;
  left: 260px;
  width: calc(100% - 260px);
  transition: all 0.5s ease;
  overflow: hidden;
}

.sidebar.close ~ .page {
  left: 78px;
  width: calc(100% - 78px);
}
