.error_page {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100%;
  align-items: center;
  display: flex;
  transform: translate(-50%, -50%);
  text-align: center;
}

.error_page h1 {
  font-size: 30px;
  font-weight: 700;
}

.error_page .error_btn {
  display: flex;
  align-items: center;
  margin-top: 14px;
  justify-content: space-between;
}