.ordercard {
  cursor: pointer;
  transition: all 0.5s;
  border-color: rgba(255, 255, 255, 0.3);
  border-style: solid;
  border-width: 0.5px;
}

.ordercard.active {
  border-color: #e40423;
  border-style: solid;
  border-width: 0.5px;
  transition: all 0.5s;
}

.line_list {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}

.line_list .obj {
  width: 3rem;
  text-align: left;
}

.line_list .dot {
  margin-right: auto;
}

.line_list .val {
  text-align: end;
}